/* ********** Custom Properties ********** */
:root {
  --first-color: #ffffff;
  --first-alpha-color: rgba(255, 255, 255, 0.75);
  --second-color: #0E2F56;
  --second-alpha-color: rgba(20, 25, 45, 0.75);
  --third-color: #118DF0;
  --third-alpha-color: rgba(17, 141, 240, 0.75);
  --white-color: #fff;
  --gray-light-color: #f3f3f3;
  --gray-color: #ccc;
  --gray-dark-color: #666;
  --black-color: #000;
  --link-color: #509ee3;
  --title-color: #333;
  --text-color: #222;
  --white-alpha-color: rgba(255, 255, 255, 0.5);
  --black-alpha-color: rgba(0, 0, 0, 0.5);
  --font: "OpenSans", sans-serif;
  --max-width: 1200px;
  --header-height: 4rem;
  --hero-image: url('images/welcome.jpg');
  --hero-attachment: fixed;
}

/* ********** Reset ********** */
html {
  box-sizing: border-box;
  font-family: var(--font);
  font-size: 16px;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: var(--text-color);
}

a {
  color: var(--link-color);
  transition: all 0.5s ease-out;
}

a:hover {
  opacity: 0.7;
}

h1 {
  margin: 0;
  font-size: 2rem;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
}

h4 {
  margin: 0;
  font-size: 1rem;
}

h5 {
  margin: 0;
  font-size: 0.85rem;
}

h6 {
  margin: 0;
  font-size: 0.7rem;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  line-height: 1.6;
}

/* ********** Components ********** */

/* ********** Carousel ********** */
@keyframes carousel-slide {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-75%);
  }
}

.carousel {
  margin: 2rem auto;
  padding: 2rem 0;
  max-width: 800px;
  text-align: center;
  overflow-x: hidden;
  background-color: var(--carousel-bg-color);
}

.carousel .slides {
  padding-left: 0;
  /* 100% por cada slide */
  width: 400%;
  display: flex;
  list-style: none;
  transition: transform 0.5s ease-in-out;
  animation: carousel-slide 12s ease-in-out 3s;
}

.imagen-redondeada {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.carousel input {
  display: none;
}

.carousel label {
  border: medium solid var(--gray-dark-color);
  border-radius: 50%;
  margin: 0 0.25rem;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
}

#slide-1:checked ~ .slides {
  transform: translateX(0%);
}

#slide-2:checked ~ .slides {
  transform: translateX(-25%);
}

#slide-3:checked ~ .slides {
  transform: translateX(-50%);
}

#slide-4:checked ~ .slides {
  transform: translateX(-75%);
}

#slide-1:checked ~ .slides-nav label#dot-1,
#slide-2:checked ~ .slides-nav label#dot-2,
#slide-3:checked ~ .slides-nav label#dot-3,
#slide-4:checked ~ .slides-nav label#dot-4 {
  background-color: var(--gray-dark-color);
}

/* ********** Contact Form ********** */

.contact-form {
  margin: 2rem auto;
  padding: 1rem;
  max-width: 800px;
}

.contact-form > * {
  padding: 0.5rem;
  margin: 1rem auto;
  display: block;
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  font-size: 0.85rem;
  font-family: var(--font);
}

.contact-form input {
  border: 0;
  padding-left: 0;
  border-bottom: thin solid var(--gray-dark-color);
}

.contact-form textarea {
  border: thin solid var(--gray-dark-color);
  resize: none;
}

.contact-form input[type="submit"] {
  margin-top: 0;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.contact-form input[type="submit"]:hover {
  opacity: 0.75;
}

.contact-form *::placeholder {
  color: var(--gray-dark-color);
}

.contact-form-response {
  padding: 1rem;
  width: 400px;
  text-align: center;
  background-color: var(--white-color);
}
.contact-form-response svg {
  margin-top: 2rem;
  width: 4rem;
  height: 4rem;
  fill: var(--first-color);
}

.modal#gracias:target {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 1024px) {
  .contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
  }

  .contact-form textarea,
  .contact-form-loader {
    grid-column: span 2;
  }

  .contact-form input[type="submit"] {
    margin-left: 0;
  }
}

/* ********** HeroImage ********** */
.hero-image {
  background-image: var(--hero-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: var(--hero-attachment);
}

.hero-image-opacity {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--hero-opacity-color);
}

.hero-image-title {
  font-size: 7.5vw;
  color: var(--hero-text-color);
}

/* ********** Menu ********** */
.menu-btn {
  outline: thin solid var(--first-color);
  border: 0;
  cursor: pointer;
  background-color: var(--second-color);
}

.menu-btn svg {
  fill: var(--first-color);
}

.menu {
  position: fixed;
  left: 0;
  bottom: var(--header-height);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--second-color);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.menu.is-active {
  opacity: 1;
  pointer-events: auto;
}

.menu a {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: var(--first-color);
}

.menu a:hover {
  color: var(--white-color);
  background-color: var(--first-color);
}

@media screen and (min-width: 1024px) {
  .menu-btn {
    display: none;
  }

  .menu {
    position: static;
    width: auto;
    flex-direction: row;
    opacity: 1;
    pointer-events: auto;
  }

  .menu a {
    padding: 0 1rem;
  }

  .menu a:last-child {
    padding-right: 0;
  }

  .menu a:hover {
    background-color: transparent;
  }
}

/* ********** Modal ********** */
.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-alpha-color);
  opacity: 0;
  pointer-events: none;
  transition: all 1s;
}

.modal-content {
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-close svg {
  width: 3rem;
  height: 3rem;
  fill: var(--first-color);
}

.modal[id|="trabajo"]:target {
  opacity: 1;
  pointer-events: auto;
}

/* ********** ProgressBar ********** */

progress {
  width: 100%;
  height: 1rem;
  background-color: var(--gray-color);
}

progress::-webkit-progress-bar {
  background-color: var(--gray-color);
}

progress::-webkit-progress-value {
  background-color: var(--gray-dark-color);
}

progress::-moz-progress-bar {
  background-color: var(--gray-dark-color);
}

.progress {
  margin: 1rem auto;
}

.progress > p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

/* ********** Utilities ********** */
.avatar {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.bg-gray-light {
  background-color: var(--gray-light-color);
}

.box-shadow-1 {
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.25);
}

.btn {
  border-radius: 0.5rem;
  padding: 1rem;
  display: inline-block;
  width: 200px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: var(--white-color);
  background-color: var(--second-color);
}

.btn:hover {
  color: var(--white-color);
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
}
.gray-scale {
  filter: grayscale(1);
}

.none {
  display: none;
}

.section {
  padding: 2rem 1rem;
}

.section-title {
  border-top: thin solid var(--second-color);
  border-bottom: thin solid var(--second-color);
  margin: 2rem auto;
  padding: 0.5rem 1rem;
  width: 250px;
  text-align: center;
  color: var(--title-color);
}

.text-first-color {
  color: var(--first-color);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media screen and (min-width: 1024px) {
  .full-lg-screen {
    width: 100%;
    min-height: 100vh;
  }

  .text-lg-center {
    text-align: center;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }
}

/* ********** Site Styles ********** */
.about > article {
  margin-bottom: 2rem;
}

.contact-card {
  margin: 1rem auto;
  padding: 1rem;
  width: 100%;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-card > svg {
  width: 2rem;
  height: 2rem;
  fill: var(--first-color);
}

.contact-card > small {
  margin-top: -1rem;
}

.footer {
  
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 1rem;
  width: 100%;
  height: var(--header-height);
  background-color: var(--second-color);
}

.header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  color: var(--first-color);
  font-weight: bold;
  font-size: 2rem;
  text-decoration: none;
}

.portfolio-card {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.portfolio-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-card-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: var(--first-alpha-color);
  color: var(--white-color);
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.portfolio-card:hover .portfolio-card-info {
  opacity: 1;
  pointer-events: auto;
}

.portfolio-card-info > div {
  padding: 1rem;
  border: thin solid var(--white-color);
  width: 100%;
  height: 100%;
}

.portfolio-modal {
  padding: 1rem;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

.portfolio-modal h3 {
  border-bottom: medium solid var(--first-color);
  margin: 1rem auto;
  padding: 0.5rem 0;
  color: var(--title-color);
}

.portfolio-details {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-auto-rows: 2rem;
}

.portfolio-details b {
  color: var(--first-color);
}

.service-card {
  margin: 1rem auto;
  padding: 1rem;
  text-align: center;
}

.service-card h3 {
  color: var(--title-color);
}

.service-card svg {
  margin-bottom: 1.25rem;
  fill: var(--first-color);
}

.social-media a {
  padding: 0 0.4rem;
  text-decoration: none;
}

.social-media svg {
  width: 1.5rem;
  height: 1.5rem;
}



@media screen and (min-width: 768px) {
  .contact-cards {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
  }

  .portfolio > .container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  .portfolio .section-title {
    grid-column: span 2;
  }

  .portfolio-modal {
    max-width: 800px;
    flex-direction: row;
  }

  .portfolio-info {
    margin-left: 1rem;
    align-self: center;
  }

  .services > .container {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    justify-content: space-between;
    align-content: center;
  }

  .services .section-title {
    grid-column: span 2;
  }
}

@media screen and (min-width: 1024px) {
  .about {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    justify-content: space-between;
    align-content: center;
  }

  .contact-cards {
    grid-template-columns: repeat(2, 48%);
  }

  .footer {
    margin-bottom: 0;
  }

  .header {
    position: sticky;
    top: 0;
    padding: 0.5rem;
    height: calc(var(--header-height) - 0.5rem);
  }

  .portfolio > .container {
    grid-template-columns: repeat(3, 1fr);
  }

  .portfolio .section-title {
    grid-column: span 3;
  }

  .services > .container {
    grid-template-columns: repeat(3, 30%);
  }

  .services .section-title {
    grid-column: span 3;
  }
}

.footer {
  margin-bottom: var(--header-height);
  padding: 0.5rem;
  text-align: center;
  color: var(--white-color);
  background-color: var(--third-color);
  box-sizing: border-box;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: block;
  color: #fff;
  transition: all 0.3s ease;
  padding: 3rem 2rem;
}

.footer_social {
color: #fff;
text-align: center;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
vertical-align: baseline;
list-style: none;
width: 100%;
position: relative;
margin-top: 1em;
flex-direction: row;
align-items: center;
display: flex;
justify-content: center;
}

.footer_contact {
  color: #fff;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: block;
  box-sizing: content-box;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  padding: 0;
}

